<template>
  <div>
    <LazyCookiesBanner
      v-show="
        getCookiesBannerState() &&
        route.path != '/authenticate' &&
        !route.path.startsWith('/child-registration')
      "
    />
    <div class="app-container">
      <div ref="mainAppDiv" class="main-app position-absolute w-100">
        <Header  v-if="route.path.startsWith('/desktop-login')"/>
        <NuxtPage />
        <LazyFooter
          v-if="
            !route.path.startsWith('/child-registration') "
        />
        <div id="hidden_gigya_div" class="d-none" aria-hidden="true"></div>
      </div>
    </div>
  </div>
</template>

<script setup>
const { calcCookiesBannerState, getCookiesBannerState } = useCookieBanner();
const route = useRoute();
const mainAppDiv = ref(null);

calcCookiesBannerState();

// Adding watchEffect since dynamic classes do not work for SSR
// It is recommended to keep CSR and SSR view similar
watchEffect(() => {
  if (getCookiesBannerState()) {
    mainAppDiv.value?.classList.add('show-cookies-banner');
  } else {
    mainAppDiv.value?.classList.remove('show-cookies-banner');
  }
});
</script>

<style lang="scss" scoped>
.show-cookies-banner {
  margin-top: 40px;

  @media (max-width: 576px) {
    margin-top: 72px;
  }
}

.main-app {
  min-height: 100vh;
}
</style>
